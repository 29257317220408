
($ => {
  $.fn.DropzoneMethods = function (settings) {
    const myDropzone = '#' + this.attr('id')
    const myDropzoneOther = '#' + this.attr('id') + 'Other'

    // eslint-disable-next-line complexity
    const previewAdd = ((dz, file) => {
      const currentList = $(dz.files.slice(-1)[0].previewElement)
      const $icon = $('<i class="d-inline c-icon c-icon-2xl"></i>')
      const extension = file.name.indexOf('.') !== -1 ? file.name.match(/\.[^.]*$/)[0].toLowerCase() : ''

      if (extension.match(/\.tsv$/) || extension.match(/\.fixed$/) || extension.match(/\.txt$/) || extension.match(/\.text$/) || extension.match(/\.csv$/) || extension.match(/\.dat$/)) {
        $icon.addClass('cil-document')
        currentList.find('.dz-image').empty().append($icon)
      } else

      if (extension.match(/\.doc.*$/) || extension.match(/\.dot.*$/)) {
        $icon.addClass('cil-file-doc')
        currentList.find('.dz-image').empty().append($icon)
      } else

      if (extension.match(/\.xls.*$/) || extension.match(/\.xlt.*$/)) {
        $icon.addClass('cil-file-xls')
        currentList.find('.dz-image').empty().append($icon)
      } else

      if (extension.match(/\.ppt$/) || extension.match(/\.pot.*$/) || extension.match(/\.pptm.*$/) || extension.match(/\.pptx.*$/)) {
        $icon.addClass('cil-file-ppt')
        currentList.find('.dz-image').empty().append($icon)
      } else

      if (extension.match(/\.pdf$/)) {
        $icon.addClass('cil-file-pdf')
        currentList.find('.dz-image').empty().append($icon)
      } else

      if (extension.match(/\.lzh$/) || extension.match(/\.zip$/)) {
        $icon.addClass('cil-file-archive')
        currentList.find('.dz-image').empty().append($icon)
      } else

      if (extension.match(/\.gif$/) || extension.match(/\.jpg$/) || extension.match(/\.bmp$/) || extension.match(/\.jpg$/) || extension.match(/\.jpeg$/) || extension.match(/\.tif$/) || extension.match(/\.tiff$/) || extension.match(/\.png$/)) {
        $icon.addClass('cil-file-image')
        currentList.find('.dz-image').empty().append($icon)
      }
    })

    this.settingsDef = {
      // url: '/',
      dzType: 'flat',
      url: 'assets/dropzone/dropzone_upload.php',
      dzUrlDelete: 'assets/dropzone/dropzone_delete.php',
      dzPreviewsOther: '',
      dzOtherUpload: '',
      previewsContainer: myDropzone + 'Previews',
      uploadMultiple: true,
      progress: false,
      previewTemplate: `
      <div class="dz-preview dz-file-preview">
        <input type="file" class="d-none dz-input"  name="check_dropzone" />
        <div class="dz-image c-icon c-icon-2xl"><img data-dz-thumbnail=""></div>
        <div class="dz-details">
          <div class="dz-filename">
            <span data-dz-name=""></span>
          </div>
        </div>
        <div class="dz-error-message alert-red-color font-weight-bold text-red-color"><span data-dz-errormessage=""></span></div>
        <div class="dz-success-mark d-none">
          <i class="c-icon c-icon-3xl cis-check-circle text-success"></i>
        </div>
        <div class="dz-error-mark d-none">
          <i class="c-icon c-icon-3xl cis-x-circle text-red-color"></i>
        </div>
        <div class="d-flex ml-auto">
          <div class="d-block my-auto">
            <div class="d-flex dz-size justify-content-center"><span data-dz-size=""></span></div>
            <div class="dz-progress progress"><span class="bg-success progress-bar progress-bar-animated progress-bar-striped text-white" data-dz-uploadprogress=""></span></div>
          </div>
          <a class="btn btn-link dz-remove ml-auto my-auto text-accent-color underline" data-dz-remove="">削除</a>
        </div>
      </div>`,
      accept(file, done) {
        previewAdd(this, file)

        // 同ファイルのプレビュー
        $(file.previewElement).siblings('.dz-preview').each((index, dzNameEl) => {
          if (file.name.match($(dzNameEl).find('[data-dz-name]').text())) {
            $(file.previewElement).remove()
            return false
          }
        })

        // name属性値変更
        $(file.previewElement).find('.dz-input').attr('name', this.options.dzInputName)

        // filelist変換
        const dt = new DataTransfer()
        dt.items.add(file)
        file.previewElement.querySelectorAll('.dz-input')[0].files = dt.files

        // プログレスバー
        if (!this.options.progress) {
          file.previewElement.querySelectorAll('.dz-progress')[0].classList.add('d-none')
        }

        done()

        if (this.options.dzType === 'modal') {
          dzAddPreviewsOther(self.options)
          $(myDropzone).closest('.modal').modal('hide')
        }

        $(this.options.dzOtherUpload).prop('disabled', false)
      },

      init() {
        $.each(this.options.dzTmpFileuploadData, (index, val) => {
          const tmpFile = {
            url: val.url,
            name: val.r_name,
            size: val.size,
            type: val.type
          }
          this.files.push(tmpFile) // add to files array
          this.emit('addedfile', tmpFile)
          this.emit('uploadprogress', tmpFile, 100)
          this.emit('processing', tmpFile)
          this.emit('success', tmpFile, { status: 'success' }, false)
          previewAdd(this, tmpFile)
          $(this.previewsContainer).find('.dz-success-mark').hide()
        })
        this.on('success', (file, response) => {
          file.serverId = response
        })
        this.on('addedfile', (file, response) => {
        })
        this.on('maxfilesexceeded', file => {
        })
        this.on('error', file => {
          $(file.previewElement).find('.dz-image').empty().append('<i class="cil-exclamation-circle text-red-color"></i>')
          $(file.previewElement).find('.dz-progress').addClass('d-none')
        })
        this.on('removedfile', file => {
          if (this.files.length === 0) {
            $(this.options.dzOtherUpload).prop('disabled', true)
          }

          if (!file.serverId) {
            return
          }

          $.post(this.options.dzUrlDelete + '?id=' + file.serverId) // Send the file id along
        })
        if (this.options.dzOtherUpload) {
          $(this.options.dzOtherUpload).on('click', () => {
            this.processQueue()
            $(this.options.dzOtherUpload).prop('disabled', true)
          })
        }
      },
      dzTmpFileuploadData: [],
      autoProcessQueue: false,
      dictFallbackMessage: 'ブラウザがドラッグアンドドロップでのアップロードに対応していません',
      dictFileTooBig: 'サイズが大きすぎます（{{filesize}}MB）。上限は{{maxFilesize}}MBです',
      dictInvalidFileType: 'この形式のファイルはアップロードできません',
      dictResponseError: 'エラーが発生しました',
      dictCancelUploadConfirmation: 'アップロードをキャンセルしてよろしいですか？',
      dictRemoveFile: 'キャンセル',
      dictMaxFilesExceeded: 'アップロード可能なファイル数の上限に達しました。',
      parallelUploads: 2,
      thumbnailHeight: 120,
      thumbnailWidth: 120,
      maxFiles: 10,
      maxFilesize: 10,
      filesizeBase: 1000,
      acceptedFiles: '.xlsx, .pdf, .png',
      mimeTypes: ['image/jpeg', 'image/png', 'image/jpg', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/docx', 'application/pdf', 'text/plain', 'application/msword', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      dzInputName: 'file[]',
      uploadprogress(file, progress, bytesSent) {
        if (file.previewElement) {
          const progressElement = file.previewElement.querySelector('[data-dz-uploadprogress]')
          progressElement.style.width = progress + '%'

          if (progress === 100) {
            $(progressElement).removeClass('progress-bar-animated progress-bar-striped').text('アップロード完了')
          }
        }

        this.processQueue()
      },

      complete(file) {
      }

    }
    this.settings = $.extend(this.settingsDef, settings)

    const dzAddPreviewsOther = function (settings) {
      $(myDropzone + 'Previews').find('.dz-preview').each((index, element) => {
        const $parent = $('<div />')
        const $child = $(element).children()
        $parent.addClass('p-1 rounded border m-1 d-flex dz-preview-hover')
        $(element).addClass('col-12 d-block p-0 border-0').removeClass('dz-success dz-preview-hover')
        $parent.append($child)
        $(element).append($parent)
        $(myDropzoneOther).find('.dropzone-previews').append(element)
      })
    }

    this.dzInit = function () {
      const $myDropzoneOther = $('<div id="' + myDropzoneOther.slice(1) + '"/>')
      const $myDropzoneOtherPreviews = $('<div class="dropzone-previews mt-3"/>')
      const acceptedFiles = this.settings.acceptedFiles.replace(/\s+|\./g, '')
      const $dom = $(`
    <div class="dropzone-container dropzone mb-0" id="${myDropzone.slice(1)}Container">
      <div class="dz-message needsclick"><span class="note needsclick">ここにファイルをドロップしてください<br><span class="small">または</span></span><br>
        <button class="btn btn-primary m-3" type="button">ファイルを選択</button><br>
        ${this.settings.maxFiles === 1 ? '' : '<span class="small">ファイル追加（Shiftキーを押しながらファイルを複数選択可能）</span><br>'}
        <span class="small">対応ファイル：${acceptedFiles}</span><br>
      </div>
    </div>
    <!--デフォルトではプレビューエリアとドロップエリアが同じだが分けている-->
    <div class="dropzone-previews" id="${myDropzone.slice(1)}Previews"></div>
    `)

      $myDropzoneOther.append($myDropzoneOtherPreviews)
      if (this.settings.dzType === 'modal') {
        $(myDropzone).closest('.modal').before($myDropzoneOther)
      }

      $(myDropzone).append($dom)
      $(myDropzone).closest('.modal').on('hidden.coreui.modal', () => {
      })
    }

    this.dzStart = function () {
      Dropzone.autoDiscover = false

      const dzContainer = new Dropzone(myDropzone + 'Container', this.settings)
    }

    this.dzRun = function () {
      if (!this.attr('id')) {
        return false
      }

      this.dzInit()
      this.dzStart()
    }

    return this
  }
})(jQuery)
